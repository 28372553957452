import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

import { paths } from '../paths'
import { SuspenseRoute } from '../components'

const Login = lazy(() => import('domains/auth/screens/Login'))
const PasswordManagementScreen = lazy(
  () => import('domains/auth/screens/PasswordManagement'),
)

const path = paths.auth

export const auth: RouteObject[] = [
  {
    path: path.login,
    element: (
      <SuspenseRoute>
        <Login />
      </SuspenseRoute>
    ),
  },
  {
    path: path.resetPassword,
    element: (
      <SuspenseRoute>
        <PasswordManagementScreen />
      </SuspenseRoute>
    ),
  },
  {
    path: path.savePassword,
    element: (
      <SuspenseRoute>
        <PasswordManagementScreen />
      </SuspenseRoute>
    ),
  },
]
