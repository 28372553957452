import { lazy } from 'react'
import { Outlet, RouteObject } from 'react-router-dom'

import { paths } from '../paths'
import { UbideskPermissions } from '../types'
import { PrivateRoute, SuspenseRoute } from '../components'

import { CustomerProvider } from 'domains/customer/screens/CustomerManagementTabs/CustomerProvider'

const CustomerManagement = lazy(
  () => import('domains/customer/screens/CustomerManagementTabs'),
)

const List = lazy(() => import('domains/accounts/screens/Accounts/Accounts'))
const Create = lazy(() => import('domains/accounts/screens/CreateAccount'))

const CreateCentral = lazy(
  () => import('domains/customer/screens/CreateCentral'),
)
const UpdateCentral = lazy(
  () => import('domains/customer/screens/UpdateCentral'),
)

const CreateContact = lazy(
  () => import('domains/customer/screens/Contact/Create'),
)
const UpdateContact = lazy(
  () => import('domains/customer/screens/Contact/Update'),
)

const CreateOfficeHour = lazy(
  () => import('domains/customer/screens/OfficeHour/screens/Create'),
)
const UpdateOfficeHour = lazy(
  () => import('domains/customer/screens/OfficeHour/screens/Update'),
)
const OfficeHourDetails = lazy(
  () => import('domains/customer/screens/OfficeHour/screens/Details'),
)

const UpdatePatrol = lazy(
  () => import('domains/customer/screens/Patrol/screens/Update'),
)
const CreatePatrol = lazy(
  () => import('domains/customer/screens/Patrol/screens/Create'),
)

const CreateEquipment = lazy(
  () => import('domains/customer/screens/Equipments/containers/Create'),
)
const UpdateEquipment = lazy(
  () => import('domains/customer/screens/Equipments/containers/Update'),
)

const path = paths.account

export const account: RouteObject[] = [
  {
    path: path.DEFAULT,
    element: (
      <PrivateRoute permissions={[UbideskPermissions.ACCOUNT]}>
        <SuspenseRoute>
          <Outlet />
        </SuspenseRoute>
      </PrivateRoute>
    ),
    children: [
      {
        path: path.create,
        element: (
          <SuspenseRoute>
            {/* 
              TODO - Trocar o componente de patrimony form para que ele nao consuma o contexto 
              como o patrimonyForm após customer criado 
            */}
            <CustomerProvider>
              <Create />
            </CustomerProvider>
          </SuspenseRoute>
        ),
      },
      {
        path: path.list,
        element: (
          <SuspenseRoute>
            <List />
          </SuspenseRoute>
        ),
      },
      {
        path: path.ID,
        element: (
          <CustomerProvider>
            <Outlet />
          </CustomerProvider>
        ),
        children: [
          {
            path: path.details,
            element: (
              <SuspenseRoute>
                <CustomerManagement />
              </SuspenseRoute>
            ),
          },
          {
            path: path.central.create,
            element: (
              <SuspenseRoute>
                <CreateCentral />
              </SuspenseRoute>
            ),
          },
          {
            path: path.central.update,
            element: (
              <SuspenseRoute>
                <UpdateCentral />
              </SuspenseRoute>
            ),
          },
          {
            path: path.contact.create,
            element: (
              <SuspenseRoute>
                <CreateContact />
              </SuspenseRoute>
            ),
          },
          {
            path: path.contact.update,
            element: (
              <SuspenseRoute>
                <UpdateContact />
              </SuspenseRoute>
            ),
          },
          {
            path: path.officeHour.create,
            element: (
              <SuspenseRoute>
                <CreateOfficeHour />
              </SuspenseRoute>
            ),
          },
          {
            path: path.officeHour.details,
            element: (
              <SuspenseRoute>
                <OfficeHourDetails />
              </SuspenseRoute>
            ),
          },
          {
            path: path.officeHour.update,
            element: (
              <SuspenseRoute>
                <UpdateOfficeHour />
              </SuspenseRoute>
            ),
          },
          {
            path: path.patrol.create,
            element: (
              <SuspenseRoute>
                <CreatePatrol />
              </SuspenseRoute>
            ),
          },
          {
            path: path.patrol.update,
            element: (
              <SuspenseRoute>
                <UpdatePatrol />
              </SuspenseRoute>
            ),
          },
          {
            path: path.equipment.create,
            element: (
              <SuspenseRoute>
                <CreateEquipment />
              </SuspenseRoute>
            ),
          },
          {
            path: path.equipment.update,
            element: (
              <SuspenseRoute>
                <UpdateEquipment />
              </SuspenseRoute>
            ),
          },
        ],
      },
    ],
  },
]
