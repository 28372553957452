import { Route, RouteObject } from 'react-router-dom'

interface BuildPath {
  path: string
  params?: Record<string, unknown>
}

/**
 * Replace paramKey for paramValue
 * @param {string} path `/route/:routeId`
 * @param {Record<string, unknown>} params `{ routeId: '123' }`
 * @returns {string} `/route/123`
 */

export const buildPath = ({ path, params = {} }: BuildPath) => {
  for (const [key, value] of Object.entries(params)) {
    path = String(path).replace(`:${key}`, String(value))
  }

  return path
}

/**
 * Render route component from react-router-dom
 * @param {RouteObject[]} default `{ element: element, path: 'path', index: true, children: [...] }`
 * @returns {JSX} `<Route key={path} index={index} path={path} element={element} children={children}/>`
 */

export const renderRoutes = (routes: RouteObject[]) =>
  routes.map(({ path, index, element, children }) => (
    <Route key={path} index={index} path={path} element={element}>
      {children && renderRoutes(children)}
    </Route>
  ))
