import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import router from 'routes'

import './App.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 60000 * 5,
      refetchOnWindowFocus: false,
      placeholderData: (prev: unknown) => prev,
    },
    mutations: {
      retry: false,
    },
  },
})

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    {router()}
  </QueryClientProvider>
)

export default App
