import { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { ToastProvider, UserInfoProvider } from 'shared/hooks'

import { MainContainer, StartedSession } from './components'
import {
  account,
  auth,
  configuration,
  occurrence,
  profile,
  exception,
  serviceOrder,
} from './domains'
import { renderRoutes } from './utils'

const publicRoutes = [...auth]

const privateRoutes = [
  ...profile,
  ...account,
  ...configuration,
  ...occurrence,
  ...serviceOrder,
  ...exception,
]

const router = () => (
  <BrowserRouter
    future={{ v7_startTransition: false, v7_relativeSplatPath: false }}
  >
    <Routes>
      {renderRoutes(publicRoutes)}
      <Route
        path=""
        element={
          <Suspense>
            <StartedSession>
              <ToastProvider>
                <UserInfoProvider>
                  <MainContainer />
                </UserInfoProvider>
              </ToastProvider>
            </StartedSession>
          </Suspense>
        }
      >
        {renderRoutes(privateRoutes)}
      </Route>
    </Routes>
  </BrowserRouter>
)

export { paths } from './paths'
export { buildPath } from './utils'

export default router
