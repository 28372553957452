import { parseCookies } from 'nookies'
import { useEffect } from 'react'
import { UserSessionStatus } from 'services/auth'
import { useUserSession } from 'shared/hooks'

export const useHandleCloseApp = () => {
  const { userId } = parseCookies()
  const { mutateCreateUserSession } = useUserSession()

  useEffect(() => {
    const handleCloseApp = () => {
      mutateCreateUserSession({
        userId,
        status: UserSessionStatus.OFFLINE,
      })
    }

    window.addEventListener('beforeunload', handleCloseApp)

    return () => window.removeEventListener('beforeunload', handleCloseApp)
  }, [])
}
