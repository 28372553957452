import { parseCookies } from 'nookies'
import { useEffect } from 'react'
import { UserSessionStatus } from 'services/auth'
import { createUserSession } from 'shared/hooks'
import { KEEP_ALIVE_PING_TIME_IN_MILLISECONDS } from 'shared/hooks/useKeepAliveUserStatus/constants'

export function useKeepAliveUserStatus() {
  const { userId } = parseCookies()

  useEffect(() => {
    if (userId) {
      const timer = setInterval(() => {
        createUserSession({
          userId,
          status: UserSessionStatus.ONLINE,
        })
      }, KEEP_ALIVE_PING_TIME_IN_MILLISECONDS)

      return () => clearInterval(timer)
    }
  }, [])
}
