import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

import { usePermissions } from 'shared/hooks'
import { UbideskPermissions } from 'routes/types'
import { paths } from 'routes'

type PrivateRouteProps = {
  children: ReactNode
  permissions: UbideskPermissions[]
}

const PrivateRoute = ({ permissions, children }: PrivateRouteProps) => {
  const { hasAccess } = usePermissions()

  const hasPermission = permissions.some((permission) => hasAccess(permission))

  if (!hasPermission) return <Navigate to={paths.auth.login} replace />

  return children
}

export default PrivateRoute
