import Axios, { AxiosInstance } from 'axios'
import { Auth } from './types'
import { endpoints } from './endpoints'

export const httpAuthClient: AxiosInstance = Axios.create({
  baseURL: 'https://oauth2.orsegups.com.br/access-token/realm',
})

export const auth = async () => {
  const params = new URLSearchParams()

  params.append('client_id', 'ubisafe-ura-client')
  params.append('grant_type', 'client_credentials')
  params.append('client_secret', 'byz1Hi4p31sRRmTv4w0Srll86Q1InrnH')

  try {
    const auth = await httpAuthClient.post<Auth>(endpoints.auth, params)

    return auth.data.access_token
  } catch (error) {
    const message = 'Could not authenticate in ura service'

    throw new Error(message)
  }
}
