import { ComponentProps } from 'react'

import joinClassNames from 'utilities/joinClassNames'

import { forwardRefAs } from '../utils'
import { AsProp, NestedColors } from '../types'
import styles from './styles.module.scss'

export type TextHeight = 'normal' | 'large'
export type TextSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl'
export type TextWeight = 'bold' | 'semibold' | 'medium' | 'regular' | 'light'

export interface TextProps extends ComponentProps<'span'> {
  weight?: TextWeight
  size?: TextSize
  height?: TextHeight
  color?: NestedColors
  as?: AsProp
}

const Text = forwardRefAs<TextProps, 'span'>(
  (
    {
      size = 'md',
      as: Tag = 'span',
      height = 'normal',
      weight = 'medium',
      color = 'element-default',
      className,
      ...props
    },
    ref,
  ) => (
    <Tag
      ref={ref}
      {...props}
      style={{
        color: `var(--${color})`,
        fontSize: `var(--font-size-${size})`,
        lineHeight: `var(--font-height-${height})`,
        fontWeight: `var(--font-weight-${weight})`,
      }}
      className={joinClassNames(styles.text, className)}
    />
  ),
)

export default Text
