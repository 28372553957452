import { ComponentProps, forwardRef, ReactNode } from 'react'
import styles from './styles.module.scss'
import Loader from 'components/LoaderV2'

interface IconButtonProps extends ComponentProps<'button'> {
  isLoading?: boolean
  children: ReactNode
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    { className, type = 'button', isLoading = false, children, ...props },
    ref,
  ) => (
    <button
      ref={ref}
      {...props}
      type={type}
      className={[
        styles.button,
        className,
        isLoading ? styles.loading : '',
      ].join(' ')}
      disabled={isLoading || props.disabled}
    >
      {isLoading ? (
        <Loader
          className={styles.loader}
          style={{
            width: children?.props?.width,
            height: children?.props?.height || children?.props?.width,
          }}
        />
      ) : (
        children
      )}
    </button>
  ),
)

IconButton.displayName = 'Button'

export default IconButton
