import { useState, ChangeEvent, forwardRef } from 'react'
import DatePickerCalendar from 'react-datepicker'
import { ptBR } from 'date-fns/locale'
import { ReactComponent as IconError } from '../../assets/svg/error.svg'
import { ReactComponent as IconCalendar } from '../../assets/svg/calendar.svg'
import { ReactComponent as IconBlueArrow } from '../../assets/svg/arrow.svg'

import 'react-datepicker/dist/react-datepicker.css'
import './Datepicker.scss'

export interface DatepickerProps {
  id: string
  errorMessage?: string
  initialDate?: Date
  endDate?: Date
  selectStart?: boolean
  selectEnd?: boolean
  onChangeDate(date: Date | null): void
  initialValueInput?: string
  onChangeInput(value: ChangeEvent<HTMLInputElement>): void
  disabled?: boolean
  placeholder?: string
}

export interface CustomButton {
  onClick?: () => void
  onKeyDown?: () => void
  disabled?: boolean
}

export interface ParamsDatepicker {
  date: Date
  changeYear?: (year: number) => void
  changeMonth?: (month: number) => void
  decreaseMonth?: () => void
  increaseMonth?: () => void
  prevMonthButtonDisabled?: boolean
  nextMonthButtonDisabled?: boolean
  decreaseYear?: () => void
  increaseYear?: () => void
  prevYearButtonDisabled?: boolean
  nextYearButtonDisabled?: boolean
}

export type MonthNumberType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11

export const Datepicker = forwardRef<HTMLInputElement, DatepickerProps>(
  (props, _ref) => {
    const {
      onChangeDate,
      onChangeInput,
      initialDate,
      endDate,
      selectEnd,
      selectStart,
      initialValueInput,
      errorMessage,
      id,
      disabled,
      placeholder,
    } = props

    const [isFocusInput, setFocusInput] = useState(false)

    const getMonthName = (monthNumber: number) => {
      switch (monthNumber) {
        case 0:
          return 'Janeiro'
        case 1:
          return 'Fevereiro'
        case 2:
          return 'Março'
        case 3:
          return 'Abril'
        case 4:
          return 'Maio'
        case 5:
          return 'Junho'
        case 6:
          return 'Julho'
        case 7:
          return 'Agosto'
        case 8:
          return 'Setembro'
        case 9:
          return 'Outubro'
        case 10:
          return 'Novembro'
        case 11:
          return 'Dezembro'
        default:
          return 'WAT?'
      }
    }

    const renderHeader = (props: ParamsDatepicker) => {
      const {
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      } = props
      return (
        <div className="calendar-top">
          <button
            type="button"
            className="calendar-button-previous"
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          >
            <IconBlueArrow className="icon-blue-arrow" />
          </button>
          <p>{`${getMonthName(date?.getMonth())} de ${date.getFullYear()}`}</p>
          <button
            type="button"
            className="calendar-button-previous calendar-button-next"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          >
            <IconBlueArrow className="icon-blue-arrow" />
          </button>
        </div>
      )
    }

    const getFocusStyle = () => {
      if (errorMessage) {
        return 'date-picker-input-container --error-datepicker'
      } else if (isFocusInput) {
        return 'date-picker-input-container container-focus'
      } else {
        return 'date-picker-input-container'
      }
    }

    const CustomInputButton = forwardRef((props: CustomButton, _ref) => {
      const { onKeyDown, onClick, disabled } = props

      return (
        <div
          id="datepicker-container-input"
          className={[getFocusStyle(), disabled && 'disabled'].join(' ')}
        >
          <input
            id={`datepicker-container-input-${id || 'id'}`}
            data-testid="datepicker-custom-input"
            maxLength={10}
            autoFocus={isFocusInput}
            onFocus={() => setFocusInput(true)}
            onBlur={() => setFocusInput(false)}
            className="date-picker-input"
            name="datepicker-input"
            value={initialValueInput}
            onChange={(value) => onChangeInput(value)}
            placeholder={placeholder || 'dd/mm/aaaa'}
            onKeyDown={onKeyDown}
            disabled={disabled}
          />
          <button
            className="calendar-button"
            onClick={() => {
              onClick && onClick()
              setFocusInput(true)
            }}
          >
            <IconCalendar />
          </button>
        </div>
      )
    })
    CustomInputButton.displayName = 'CustomInputButton'

    return (
      <div id={id} className="wrapper">
        <DatePickerCalendar
          disabled={disabled}
          selected={initialDate}
          onChange={(date) => onChangeDate(date)}
          selectsStart={selectStart || false}
          selectsEnd={selectEnd || false}
          startDate={initialDate}
          endDate={endDate}
          minDate={selectEnd ? initialDate : null}
          locale={ptBR}
          dateFormat="dd/MM/yyyy"
          customInput={<CustomInputButton />}
          renderCustomHeader={(props) => renderHeader(props)}
        />
        {errorMessage && (
          <div className="error-wrapper-datepicker">
            <span className="error-innerWrapper-datepicker">
              <IconError className="error-icon-datepicker" />
              <p className="error-text-datepicker">{errorMessage}</p>
            </span>
          </div>
        )}
      </div>
    )
  },
)

Datepicker.displayName = 'Datepicker'

export default Datepicker
