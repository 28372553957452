import { lazy } from 'react'
import { Outlet, RouteObject } from 'react-router-dom'

import { paths } from '../paths'
import { UbideskPermissions } from '../types'
import { PrivateRoute, SuspenseRoute } from '../components'

const Cftv = lazy(() => import('domains/occurrence/screens/CFTV'))
const List = lazy(() => import('domains/occurrence/screens/Occurrences'))
const Map = lazy(() => import('domains/occurrence/screens/DisplacementMap'))
const Attendance = lazy(() => import('domains/occurrence/screens/Attendance'))
const Search = lazy(() => import('domains/occurrence/screens/SearchOccurrence'))
const Management = lazy(
  () => import('domains/occurrence/screens/OccurrenceManagement'),
)

const occurrencePath = paths.occurrence

export const occurrence: RouteObject[] = [
  {
    path: occurrencePath.DEFAULT,
    element: (
      <PrivateRoute permissions={[UbideskPermissions.ACCOUNT]}>
        <Outlet />
      </PrivateRoute>
    ),
    children: [
      {
        path: occurrencePath.list,
        element: (
          <SuspenseRoute>
            <List />
          </SuspenseRoute>
        ),
      },
      {
        path: occurrencePath.search,
        element: (
          <SuspenseRoute>
            <Search />
          </SuspenseRoute>
        ),
      },
      {
        path: occurrencePath.map,
        element: (
          <SuspenseRoute>
            <Map />
          </SuspenseRoute>
        ),
      },
      {
        path: occurrencePath.attendance,
        element: (
          <SuspenseRoute>
            <Attendance />
          </SuspenseRoute>
        ),
      },
      {
        path: occurrencePath.management,
        element: (
          <SuspenseRoute>
            <Management />
          </SuspenseRoute>
        ),
      },
      {
        path: occurrencePath.cftv,
        element: (
          <SuspenseRoute>
            <Cftv />
          </SuspenseRoute>
        ),
      },
    ],
  },
]
