import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

import { paths } from '../paths'
import { SuspenseRoute } from '../components'

const Profile = lazy(() => import('domains/profile/screens/Profile'))
const ChangePasssword = lazy(
  () => import('domains/profile/screens/ChangePassword'),
)

const path = paths.profile

export const profile: RouteObject[] = [
  {
    path: path.info,
    element: (
      <SuspenseRoute>
        <Profile />
      </SuspenseRoute>
    ),
  },
  {
    path: path.changePassword,
    element: (
      <SuspenseRoute>
        <ChangePasssword />
      </SuspenseRoute>
    ),
  },
]
