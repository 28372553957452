import { ReactNode, Suspense } from 'react'

import { Loader } from 'components'

type PrivateRouteProps = {
  children: ReactNode
}

const SuspenseRoute = ({ children }: PrivateRouteProps) => {
  return <Suspense fallback={<Loader isVisible />}>{children}</Suspense>
}

export default SuspenseRoute
