import { lazy } from 'react'
import { Outlet, RouteObject } from 'react-router-dom'

import { paths } from '../paths'
import { UbideskPermissions } from '../types'
import { PrivateRoute, SuspenseRoute } from '../components'

const InProgress = lazy(
  () => import('domains/serviceOrders/screens/InProgress'),
)
const InValidation = lazy(
  () => import('domains/serviceOrders/screens/InValidation'),
)
const UnScheduled = lazy(
  () => import('domains/serviceOrders/screens/UnScheduled'),
)
const Search = lazy(() => import('domains/serviceOrders/screens/Search'))
const Calendar = lazy(
  () => import('domains/serviceOrders/screens/CalendarBlocking'),
)

const Details = lazy(() => import('domains/serviceOrder/screens/Details'))
const Update = lazy(() => import('domains/serviceOrder/screens/Update'))
const Create = lazy(() => import('domains/serviceOrder/screens/Create'))

const path = paths.serviceOrder

export const serviceOrder: RouteObject[] = [
  {
    path: path.DEFAULT,
    element: (
      <PrivateRoute permissions={[UbideskPermissions.SERVICE_ORDER]}>
        <Outlet />
      </PrivateRoute>
    ),
    children: [
      {
        path: path.details,
        element: (
          <SuspenseRoute>
            <Details />
          </SuspenseRoute>
        ),
      },
      {
        path: path.update,
        element: (
          <SuspenseRoute>
            <Update />
          </SuspenseRoute>
        ),
      },
      {
        path: path.create,
        element: (
          <SuspenseRoute>
            <Create />
          </SuspenseRoute>
        ),
      },
      {
        path: path.inProgress,
        element: (
          <SuspenseRoute>
            <InProgress />
          </SuspenseRoute>
        ),
      },
      {
        path: path.inValidation,
        element: (
          <SuspenseRoute>
            <InValidation />
          </SuspenseRoute>
        ),
      },
      {
        path: path.unscheduled,
        element: (
          <SuspenseRoute>
            <UnScheduled />
          </SuspenseRoute>
        ),
      },
      {
        path: path.calendarBlocking,
        element: (
          <PrivateRoute
            permissions={[UbideskPermissions.SO_TECHNICIAN_SCHEDULE_PAUSE]}
          >
            <SuspenseRoute>
              <Calendar />
            </SuspenseRoute>
          </PrivateRoute>
        ),
      },
      {
        path: path.search,
        element: (
          <SuspenseRoute>
            <Search />
          </SuspenseRoute>
        ),
      },
    ],
  },
]
