import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'

import { FallBackError, Loader } from 'components'
import { useHandleCloseApp, usePageTitle } from 'routes/hooks'
import { useToast, useUserInfo, useKeepAliveUserStatus } from 'shared/hooks'

import NavBar from '../Navbar'
import classes from './MainContainer.module.scss'

const MainContainer = () => {
  const { userInfo } = useUserInfo()
  const [changedStation, setChangedStation] = useState(false)

  const { addToast } = useToast()

  usePageTitle()
  useHandleCloseApp()
  useKeepAliveUserStatus()

  useEffect(() => {
    if (userInfo?.station && changedStation) {
      addToast({
        message: `Você está usando o posto de atendimento ${
          userInfo?.station?.name || ''
        }`,
      })
      setChangedStation(false)
    }
  }, [userInfo, addToast, changedStation])

  if (!userInfo) {
    return <Loader isVisible />
  }

  return (
    <ErrorBoundary
      key={window.location.pathname}
      fallbackRender={({ error }) => <FallBackError error={error} />}
    >
      <div className={classes.container}>
        <NavBar />
        <div className={classes.content}>
          <Outlet />
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default MainContainer
