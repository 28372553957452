import { useMutation } from '@tanstack/react-query'
import { AuthDriver, CreateUserSessionRequest } from 'services/auth'

export async function createUserSession(payload: CreateUserSessionRequest) {
  return await AuthDriver.createUserSession(payload)
}

export const useUserSession = () => {
  const { mutate: mutateCreateUserSession, status: createUserSessionStatus } =
    useMutation({
      mutationFn: (payload: CreateUserSessionRequest) =>
        createUserSession(payload),
    })

  return {
    mutateCreateUserSession,
    createUserSessionStatus,
  }
}
