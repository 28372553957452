import { paths } from './paths'

type NestedRouteTitles<T> = {
  [K in keyof T]: T[K] extends string ? string : NestedRouteTitles<T[K]>
}

export const titles: NestedRouteTitles<typeof paths> = {
  auth: {
    login: 'Login',
    resetPassword: 'Resetar senha',
    savePassword: 'Salvar senha',
  },
  profile: {
    info: 'Meus dados',
    changePassword: 'Redefinição de senha do perfil',
  },
  account: {
    DEFAULT: 'Conta',
    list: 'Contas',
    create: 'Nova conta',
    ID: 'Detalhes da conta',
    details: 'Detalhes da conta',
    central: {
      create: 'Nova central',
      update: 'Editar central',
    },
    contact: {
      create: 'Novo contato',
      update: 'Editar contato',
    },
    officeHour: {
      create: 'Novo horário',
      details: 'Detalhes do horário',
      update: 'Editar horário',
    },
    patrol: {
      create: 'Nova ocorrência periódica',
      update: 'Editar ocorrência periódica',
    },
    equipment: {
      create: 'Novo equipamento',
      update: 'Editar equipamento',
    },
  },
  configuration: {
    DEFAULT: 'Configuração',
    holiday: {
      list: 'Feriados',
      create: 'Criar feriado',
      update: 'Editar de feriado',
    },
    vehicle: {
      list: 'Viaturas',
      create: 'Criar viatura',
      update: 'Editar viatura',
    },
    user: {
      list: 'Gestão de usuários',
      create: 'Novo usuário',
      update: 'Editar de usuário',
    },
    attendanceProfile: {
      list: 'Perfis de atendimento',
      create: 'Novo perfil de atendimento',
      update: 'Editar perfil de atendimento',
    },
    attendancePolicy: {
      list: 'Regra de atendimento',
      create: 'Nova regra de atendimento',
      update: 'Editar regra de atendimento',
    },
    centralRefused: {
      list: 'Habilitação de GPRS/Ethernet',
    },
  },
  occurrence: {
    DEFAULT: 'Ocorrências',
    map: 'Mapa de ocorrências',
    list: 'Lista de ocorrências',
    search: 'Buscar ocorrências',
    management: 'Gestão de ocorrências',
    cftv: 'CFTV de ocorrência',
    attendance: 'Atendimento de ocorrência',
  },
  serviceOrder: {
    DEFAULT: 'Ordens de serviço',
    create: 'Nova ordem de serviço',
    search: 'Ordens de serviço',
    details: 'Detalhes da ordem de serviço',
    update: 'Editar ordem de serviço',
    inProgress: 'Ordens de serviço em andamento',
    unscheduled: 'Ordens de serviço Não agendadas',
    inValidation: 'Ordens de serviço em validação',
    calendarBlocking: 'Bloqueio de agenda',
  },
} as const
