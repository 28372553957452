import { parseCookies } from 'nookies'
import { ReactNode, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { paths } from 'routes/paths'

interface StartedSessionProps {
  children: ReactNode
}

const StartedSession = ({ children }: StartedSessionProps) => {
  const location = useLocation()
  const navigate = useNavigate()

  const { userId } = parseCookies()

  useEffect(() => {
    if (!userId && location.pathname !== paths.auth.login) {
      navigate(paths.auth.login, { state: { from: location } })
    }
  }, [])

  return children
}

export default StartedSession
