import { lazy } from 'react'
import { Outlet, RouteObject } from 'react-router-dom'

import { paths } from '../paths'
import { UbideskPermissions } from '../types'
import { PrivateRoute, SuspenseRoute } from '../components'

const HolidayList = lazy(() => import('domains/holiday/screens/List'))
const CreateHoliday = lazy(() => import('domains/holiday/screens/Create'))
const UpdateHoliday = lazy(() => import('domains/holiday/screens/Update'))

const VehicleList = lazy(() => import('domains/vehicles/screens/Vehicles'))
const CreateVehicle = lazy(
  () => import('domains/vehicles/screens/CreateVehicle'),
)
const UpdateVehicle = lazy(() => import('domains/vehicles/screens/EditVehicle'))

const UserList = lazy(() => import('domains/user/screens/List'))
const CreateUser = lazy(() => import('domains/user/screens/CreateUser'))
const UpdateUser = lazy(() => import('domains/user/screens/UpdateUser'))

const {
  holiday: holidayPath,
  vehicle: vehiclePath,
  user: userPath,
  attendancePolicy: attendancePolicyPath,
  attendanceProfile: attendanceProfilePath,
  centralRefused: centralRefusedPath,
} = paths.configuration

const AttendancePolicy = lazy(
  () => import('domains/attendancePolicy/screens/AttendancePolicy'),
)
const CreateAttendancePolicy = lazy(
  () => import('domains/attendancePolicy/screens/CreateAttendancePolicy'),
)
const UpdateAttendancePolicy = lazy(
  () => import('domains/attendancePolicy/screens/UpdateAttendancePolicy'),
)

const AttendanceProfile = lazy(
  () => import('domains/attendanceProfile/screens/AttendanceProfile'),
)
const CreateAttendanceProfile = lazy(
  () => import('domains/attendanceProfile/screens/CreateAttendanceProfile'),
)
const UpdateAttendanceProfile = lazy(
  () => import('domains/attendanceProfile/screens/UpdateAttendanceProfile'),
)

const CentralRefusedList = lazy(
  () => import('domains/centralRefused/screens/List'),
)

export const configuration: RouteObject[] = [
  {
    path: holidayPath.list,
    element: (
      <PrivateRoute permissions={[UbideskPermissions.CONFIG_HOLIDAY]}>
        <Outlet />
      </PrivateRoute>
    ),
    children: [
      {
        path: holidayPath.list,
        element: (
          <SuspenseRoute>
            <HolidayList />
          </SuspenseRoute>
        ),
      },
      {
        path: holidayPath.update,
        element: (
          <SuspenseRoute>
            <UpdateHoliday />
          </SuspenseRoute>
        ),
      },
      {
        path: holidayPath.create,
        element: (
          <SuspenseRoute>
            <CreateHoliday />
          </SuspenseRoute>
        ),
      },
    ],
  },
  {
    path: vehiclePath.list,
    element: (
      <PrivateRoute permissions={[UbideskPermissions.CONFIG_VEHICLE]}>
        <Outlet />
      </PrivateRoute>
    ),
    children: [
      {
        path: vehiclePath.list,
        element: (
          <SuspenseRoute>
            <VehicleList />
          </SuspenseRoute>
        ),
      },
      {
        path: vehiclePath.create,
        element: (
          <SuspenseRoute>
            <CreateVehicle />
          </SuspenseRoute>
        ),
      },
      {
        path: vehiclePath.update,
        element: (
          <SuspenseRoute>
            <UpdateVehicle />
          </SuspenseRoute>
        ),
      },
    ],
  },
  {
    path: userPath.list,
    element: (
      <PrivateRoute
        permissions={[
          UbideskPermissions.CONFIG_USER,
          UbideskPermissions.CONFIG_USER_WRITE,
        ]}
      >
        <Outlet />
      </PrivateRoute>
    ),
    children: [
      {
        path: userPath.list,
        element: (
          <SuspenseRoute>
            <UserList />
          </SuspenseRoute>
        ),
      },
      {
        path: userPath.create,
        element: (
          <SuspenseRoute>
            <CreateUser />
          </SuspenseRoute>
        ),
      },
      {
        path: userPath.update,
        element: (
          <SuspenseRoute>
            <UpdateUser />
          </SuspenseRoute>
        ),
      },
    ],
  },
  {
    path: attendancePolicyPath.list,
    element: (
      <PrivateRoute permissions={[UbideskPermissions.CONFIG_ATTENDANCE_POLICY]}>
        <Outlet />
      </PrivateRoute>
    ),
    children: [
      {
        path: attendancePolicyPath.list,
        element: (
          <SuspenseRoute>
            <AttendancePolicy />
          </SuspenseRoute>
        ),
      },
      {
        path: attendancePolicyPath.create,
        element: (
          <SuspenseRoute>
            <CreateAttendancePolicy />
          </SuspenseRoute>
        ),
      },
      {
        path: attendancePolicyPath.update,
        element: (
          <SuspenseRoute>
            <UpdateAttendancePolicy />
          </SuspenseRoute>
        ),
      },
    ],
  },
  {
    path: attendanceProfilePath.list,
    element: (
      <PrivateRoute
        permissions={[UbideskPermissions.CONFIG_ATTENDANCE_PROFILE]}
      >
        <Outlet />
      </PrivateRoute>
    ),
    children: [
      {
        path: attendanceProfilePath.list,
        element: (
          <SuspenseRoute>
            <AttendanceProfile />
          </SuspenseRoute>
        ),
      },
      {
        path: attendanceProfilePath.create,
        element: (
          <SuspenseRoute>
            <CreateAttendanceProfile />
          </SuspenseRoute>
        ),
      },
      {
        path: attendanceProfilePath.update,
        element: (
          <SuspenseRoute>
            <UpdateAttendanceProfile />
          </SuspenseRoute>
        ),
      },
    ],
  },
  {
    path: centralRefusedPath.list,
    element: (
      <PrivateRoute
        permissions={[UbideskPermissions.CONFIG_CENTRAL_REFUSED_CONNECTIONS]}
      >
        <Outlet />
      </PrivateRoute>
    ),
    children: [
      {
        path: centralRefusedPath.list,
        element: (
          <SuspenseRoute>
            <CentralRefusedList />
          </SuspenseRoute>
        ),
      },
    ],
  },
]
