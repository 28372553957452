import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const Home = lazy(() => import('../screens/Home'))
const PageNotFound = lazy(() => import('../screens/PageNotFound'))

export const exception: RouteObject[] = [
  { index: true, element: <Home /> },
  { path: '*', element: <PageNotFound /> },
]
