import { ReactNode } from 'react'

import { UbideskPermissions } from 'routes/types'
import { Icon } from 'components'
import { paths } from 'routes/paths'

export interface NavItem {
  title: string
  path: string
  icon?: ReactNode
  subItems?: NavItem[]
  permission: UbideskPermissions[]
}

const page = '?page=1'

export const sidebarData: NavItem[] = [
  {
    title: 'Contas',
    path: paths.account.list,
    permission: [UbideskPermissions.ACCOUNT],
    icon: (
      <Icon name="customer" color="element-default" width={20} height={20} />
    ),
  },
  {
    title: 'Ocorrências',
    path: paths.occurrence.DEFAULT,
    permission: [UbideskPermissions.ACCOUNT],
    icon: (
      <Icon name="occurrence" color="element-default" width={20} height={20} />
    ),
    subItems: [
      {
        title: 'Atendimento',
        path: paths.occurrence.search,
        permission: [UbideskPermissions.ACCOUNT],
      },
      {
        title: 'Gestão de ocorrências',
        path: paths.occurrence.management,
        permission: [UbideskPermissions.ACCOUNT],
      },
      {
        title: 'Mapa de deslocamento',
        path: paths.occurrence.map,
        permission: [UbideskPermissions.ACCOUNT],
      },
      {
        title: 'Listagem geral',
        path: paths.occurrence.list,
        permission: [UbideskPermissions.ACCOUNT],
      },
    ],
  },
  {
    title: 'Ordens de Serviço',
    path: paths.serviceOrder.DEFAULT,
    permission: [UbideskPermissions.SERVICE_ORDER],
    icon: (
      <Icon
        name="service-order"
        color="element-default"
        width={20}
        height={20}
      />
    ),
    subItems: [
      {
        title: 'Cadastro',
        path: paths.serviceOrder.create,
        permission: [UbideskPermissions.SERVICE_ORDER],
      },
      {
        title: 'Listagem geral',
        path: paths.serviceOrder.search + page,
        permission: [UbideskPermissions.SERVICE_ORDER],
      },
      {
        title: 'Por técnico',
        path: paths.serviceOrder.inProgress + page,
        permission: [UbideskPermissions.SERVICE_ORDER],
      },
      {
        title: 'Aguardando agendamento',
        path: paths.serviceOrder.unscheduled + page,
        permission: [UbideskPermissions.SERVICE_ORDER],
      },
      {
        title: 'Aguardando validação',
        path: paths.serviceOrder.inValidation + page,
        permission: [UbideskPermissions.SERVICE_ORDER],
      },
      {
        title: 'Bloqueio de agenda',
        path: paths.serviceOrder.calendarBlocking + page,
        permission: [UbideskPermissions.SO_TECHNICIAN_SCHEDULE_PAUSE],
      },
    ],
  },
  {
    title: 'Configurações',
    path: paths.configuration.DEFAULT,
    permission: [UbideskPermissions.CONFIG],
    icon: (
      <Icon name="settings" color="element-default" width={20} height={20} />
    ),
    subItems: [
      {
        title: 'Gestão de usuários',
        path: paths.configuration.user.list,
        permission: [
          UbideskPermissions.CONFIG_USER,
          UbideskPermissions.CONFIG_USER_WRITE,
        ],
      },
      {
        title: 'Viaturas',
        path: paths.configuration.vehicle.list + page,
        permission: [UbideskPermissions.CONFIG_VEHICLE],
      },
      {
        title: 'Habilitar GPRS/Ethernet',
        path: paths.configuration.centralRefused.list,
        permission: [UbideskPermissions.CONFIG_CENTRAL_REFUSED_CONNECTIONS],
      },
      {
        title: 'Perfis de atendimento',
        path: paths.configuration.attendanceProfile.list + page,
        permission: [UbideskPermissions.CONFIG_ATTENDANCE_PROFILE],
      },
      {
        title: 'Regras de atendimento',
        path: paths.configuration.attendancePolicy.list + page,
        permission: [UbideskPermissions.CONFIG_ATTENDANCE_POLICY],
      },
      {
        title: 'Feriados',
        path: paths.configuration.holiday.list + page,
        permission: [UbideskPermissions.CONFIG_HOLIDAY],
      },
    ],
  },
]
