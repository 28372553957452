import { useEffect } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { paths } from '../paths'
import { titles } from '../titles'

type RouteTree<T> = {
  [K in keyof T]: T[K] extends string ? string : RouteTree<T[K]>
}

function getTitle<T extends Record<string, string | object>>(
  pathsObj: RouteTree<T>,
  titlesObj: Partial<RouteTree<T>>,
  currentPath: string,
): string {
  for (const key of Object.keys(pathsObj) as Array<keyof T>) {
    const value = pathsObj[key]
    const title = titlesObj[key]

    if (typeof value === 'string' && matchPath(value, currentPath)) {
      return String(title) || ''
    }

    if (typeof value === 'object' && typeof title === 'object') {
      const nestedTitle = getTitle(value, title, currentPath)

      if (nestedTitle) return nestedTitle
    }
  }

  return ''
}

export const usePageTitle = () => {
  const location = useLocation()

  useEffect(() => {
    const title = getTitle(paths, titles, location.pathname)

    document.title = `${title ? title + ' | ' : ''}UbiDesk`
  }, [location])
}
