import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
  FC,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Loader, Toast } from 'components'

import { ServiceOrderAggregatedQuery } from 'services/serviceOrder'
import { useGetCustomer, useToast, useToggle } from 'shared/hooks'
import { CustomerResponse } from 'services/customer/types'

import { getServiceOrderToastMessage } from 'domains/customer/utilities/serviceOrder/getServiceOrderMessage'
import { useGetServiceOrders } from 'shared/hooks/serviceOrder/useGetServiceOrders'
import { useGetAccount } from 'services'
import { buildPath, paths } from 'routes'
import styles from './CustomerManagementTabs.module.scss'

type ToastInfo = {
  message: string
  buttonTitle: string
}

export interface CustomerContextType {
  customer: CustomerResponse | undefined
  patrimony: { id: string; name: string } | undefined
  account:
    | {
        id: string
        name: string
        code: string
        serviceType?: { id: string; name: string }
      }
    | undefined
}

export const CustomerContext = createContext<CustomerContextType | undefined>(
  undefined,
)

export function useCustomerContext(): CustomerContextType {
  const context = useContext(CustomerContext)

  if (!context) {
    throw new Error('You need to use CustomerContext within a CustomerProvider')
  }

  return context
}

interface Provider {
  children: ReactNode
}

export const CustomerProvider: FC<Provider> = ({ children }) => {
  const { accountId } = useParams()
  const [serviceOrder, setServiceOrder] =
    useState<ServiceOrderAggregatedQuery>()

  const [toastInfo, setToastInfo] = useState<ToastInfo>({
    message: '',
    buttonTitle: '',
  })

  const { data, isLoading } = useGetAccount(accountId)

  const navigate = useNavigate()

  const { data: customer, isLoading: customerIsLoading } = useGetCustomer(
    data?.customer?.id || '',
  )

  const toast = useToast()
  const informativeToast = useToggle()

  const { data: fetchedServiceOrders, status: serviceOrderStatus } =
    useGetServiceOrders(
      {
        accountIds: [accountId || ''],
        status: [
          'IN_PROGRESS',
          'OPEN',
          'SCHEDULED',
          'RESCHEDULED',
          'PAUSED',
          'DISAPPROVED',
        ],
      },
      Boolean(accountId),
    )

  useEffect(() => {
    if (fetchedServiceOrders?.totalElements) {
      const [firstServiceOrder] = fetchedServiceOrders.data

      setServiceOrder(firstServiceOrder)
      setToastInfo(
        getServiceOrderToastMessage(
          firstServiceOrder.status,
          firstServiceOrder.number,
        ),
      )
      informativeToast.show()
    }

    if (serviceOrderStatus === 'error') {
      toast.addToast({
        type: 'alert',
        message:
          'Não foi possível recuperar as ordens de serviço. Tente novamente',
      })
    }
  }, [fetchedServiceOrders, serviceOrderStatus])

  return (
    <CustomerContext.Provider
      value={{
        account: data?.account,
        customer,
        patrimony: data?.patrimony,
      }}
    >
      <Toast
        isVisible={informativeToast.isVisible}
        onClose={informativeToast.hide}
        type="informative"
        autohideTimeout={0}
      >
        {toastInfo.message}
        <button
          aria-label="navigate-to-service-order"
          className={styles.toastButton}
          onClick={() => {
            if (serviceOrder) {
              informativeToast.hide()
              navigate(
                buildPath({
                  path: paths.serviceOrder.details,
                  params: { serviceOrderId: serviceOrder.id },
                }),
              )
            }
          }}
        >
          {toastInfo.buttonTitle}
        </button>
      </Toast>
      {isLoading || customerIsLoading ? <Loader isVisible /> : children}
    </CustomerContext.Provider>
  )
}
