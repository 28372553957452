import { Children, Fragment, ReactElement, ReactNode } from 'react'
import styles from './TabBar.module.scss'
import joinClassNames from 'utilities/joinClassNames'

export interface TabProps {
  id: string
  label: string
  idSelected: string
  children: ReactNode
  onClick(value: string): void
}

export const Tab = (props: TabProps) => {
  const { label, onClick, id, idSelected } = props

  return (
    <div
      data-testid={id}
      className={[
        styles.tabWrapper,
        id === idSelected && styles.wrapperSelected,
      ]
        .filter(Boolean)
        .join(' ')}
      onClick={() => onClick(id)}
    >
      <div
        className={[styles.tab, id === idSelected && styles.activeTab]
          .filter(Boolean)
          .join(' ')}
        id={id}
      >
        {label}
      </div>
    </div>
  )
}

interface TabBarProps {
  className?: string
  fullSize?: boolean
  size?: 'md' | 'sm'
  children: ReactNode
}

export const TabBar = ({
  children,
  size = 'md',
  fullSize = false,
  ...props
}: TabBarProps) => {
  const childrenArray = Children.toArray(children)

  return (
    <>
      <div
        className={joinClassNames(
          styles.tabRow,
          fullSize && styles.fullSize,
          styles[size],
        )}
      >
        {children}
      </div>
      {childrenArray.map((item) => {
        const content = item as ReactElement

        const element = content.props.children
        const idSelected = content.props.idSelected
        const id = content.props.id

        return (
          <Fragment key={id}>
            {id === idSelected && (
              <div className={[styles.content, props.className].join(' ')}>
                {element}
              </div>
            )}
          </Fragment>
        )
      })}
    </>
  )
}

TabBar.Tab = Tab
