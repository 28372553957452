import Axios, { AxiosInstance } from 'axios'
import { endpoints } from './endpoints'
import buildUrl from 'services/utils/buildUrl'

interface Call {
  ramal: string
  phoneNumber: string
  token: string
}

export const httpCallClient: AxiosInstance = Axios.create({
  baseURL: 'https://call.orsegups.com.br/api/v1',
})

export const call = async ({ ramal, phoneNumber, token }: Call) => {
  try {
    const call = await httpCallClient.post(
      buildUrl({ route: endpoints.call, params: { ramal, phoneNumber } }),
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    )

    return call
  } catch (error) {
    const message = 'Could not call usign ura service'

    throw new Error(message)
  }
}
