/**
 * Where all screen routes is define.
 *
 * Structure explanation:
 * - Each object is as a module (ex: `auth`, `configuration`).
 * - The values can be `string` or `object` as module
 * - Dynamic parameters should be `:paramName` (ex: `:holidayId`).
 * - Add `as const` at the end of the object to export each value
 *
 * @example
 * ```ts
 *   module: {
 *     DEFAULT: '/module', To be used on NavBar component,
 *     list: '/module/list', To list entities,
 *     create: '/module/create', To create entity,
 *     update: '/module/:moduleId/create', To update entity,
 *     bigName: '/module/big-name', Use `kebab-case`, if there is space between words
 *     subModule: ...module, The same pattern
 *   } as const
 * ```
 */

const auth = {
  login: '/login',
  resetPassword: '/reset-password/*',
  savePassword: '/save-password/*',
} as const

const profile = {
  info: '/profile',
  changePassword: '/profile/password',
} as const

const account = {
  DEFAULT: '/account',
  list: '/account/list',
  create: '/account/create',
  ID: '/account/:accountId',
  details: '/account/:accountId/details',
  central: {
    create: '/account/:accountId/central/create',
    update: '/account/:accountId/central/:centralId/update',
  },
  contact: {
    create: '/account/:accountId/contact/create',
    update: '/account/:accountId/contact/:contactId/update',
  },
  officeHour: {
    create: '/account/:accountId/officeHour/create',
    details: '/account/:accountId/officeHour/:officeHourId',
    update: '/account/:accountId/officeHour/:officeHourId/update',
  },
  patrol: {
    create: '/account/:accountId/patrol/create',
    update: '/account/:accountId/patrol/:patrolId/update',
  },
  equipment: {
    create: '/account/:accountId/equipment/create',
    update: '/account/:accountId/equipment/:equipmentId/update',
  },
} as const

const configuration = {
  DEFAULT: '/configuration',
  holiday: {
    list: '/configuration/holiday',
    create: '/configuration/holiday/create',
    update: '/configuration/holiday/:holidayId/update',
  },
  vehicle: {
    list: '/configuration/vehicle',
    create: '/configuration/vehicle/create',
    update: '/configuration/vehicle/:vehicleId/update',
  },
  user: {
    list: '/configuration/user',
    create: '/configuration/user/create',
    update: '/configuration/user/:userId/update',
  },
  attendanceProfile: {
    list: '/configuration/attendanceProfile',
    create: '/configuration/attendanceProfile/create',
    update: '/configuration/attendanceProfile/:attendanceProfileId/update',
  },
  attendancePolicy: {
    list: '/configuration/attendancePolicy',
    create: '/configuration/attendancePolicy/create',
    update: '/configuration/attendancePolicy/:attendancePolicyId/update',
  },
  centralRefused: {
    list: '/configuration/central-refused',
  },
} as const

const occurrence = {
  DEFAULT: '/occurrence',
  map: '/occurrence/map',
  list: '/occurrence/list',
  search: '/occurrence/search',
  management: '/occurrence/management',
  cftv: '/occurrence/:occurrenceId/cftv',
  attendance: '/occurrence/:occurrenceId/attendance',
} as const

/* 
  TODO implementar as rotas novas junto com o time de suporte

    DEFAULT: '/service-order',
    create: '/service-order/create',
    search: '/service-order/search',
    details: '/service-order/:serviceOrderId',
    update: '/service-order/:serviceOrderId/update',
    inProgress: '/service-order/in-progress',
    unscheduled: '/service-order/unscheduled',
    inValidation: '/service-order/in-validation',
    calendarBlocking: '/service-order/calendar-blocking',
*/

const serviceOrder = {
  DEFAULT: '/so',
  create: '/so/create',
  search: '/so/search',
  unscheduled: '/so/unscheduled',
  inValidation: '/so/in_validation',
  calendarBlocking: '/so/calendarBlocking',
  inProgress: '/so/inProgressServiceOrders',
  details: '/so/:serviceOrderId',
  update: '/so/update/:serviceOrderId',
} as const

export const paths = {
  auth,
  profile,
  account,
  occurrence,
  configuration,
  serviceOrder,
} as const
