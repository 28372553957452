import ReactDOM from 'react-dom/client'

import 'assets/styles/reset.scss'
import 'assets/theme/index.scss'
import 'assets/scrollbar/index.scss'

import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root')!)

root.render(<App />)
